import React from "react";
import { Link } from 'react-router-dom';
import '../asstes/css/footer.css';
import foot from '../asstes/images/mini.png';
import { FaFacebookF, FaInstagram, FaYoutube, FaTelegramPlane } from 'react-icons/fa'; // Иконки соцсетей
import location from '../asstes/images/destination.svg';
import phone from '../asstes/images/icon-phone.svg';
import mail from '../asstes/images/Icon-messege.svg'
import right from '../asstes/images/right-arrow.svg'
import itlogo from '../asstes/images/logoIt.png'
const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="info-company-footer">
                    <img src={foot} alt="" className="logo-footer" width={45}/>
                    <p>Bizni ijtimoiy tarmoqlarda kuzatib boring</p>
                    <div className="sotset-footer">
                    <div className="socials">
                        <Link to="/" target='_blank' className='social-link'>
                        <FaFacebookF className='social-icon' />
                        </Link>
                        <Link to="/" className='social-link'>
                        <FaInstagram className='social-icon' />
                        </Link>
                        <Link to="/" className='social-link'>
                        <FaYoutube className='social-icon' />
                        </Link>
                        <Link to="/" className='social-link'>
                        <FaTelegramPlane className='social-icon' />
                        </Link>
                    </div>
                    </div>
                </div>
                <div className="links-pages-footer">
                  <h6 class="header-footer-boxes">Lokomotiv depo</h6>
                  <ul style={{paddingLeft: '0'}}>
                    <li>
                     <a href="/about">Depo haqida</a>
                    </li>
                    <li>
                     <a href="/news">Yangiliklar</a>
                    </li>
                    <li>
                       <a href="/products">Interaktiv xizmatlar</a>
                    </li>
                    <li>
                      <a href="/media">Mediateka</a>
                    </li>
                  </ul>
                </div>
                <div className="destination-footer">
                  <h6 class="header-footer-boxes">Manzil</h6>
                  <div>
                        <div>
                            <img src={location} alt="location" style={{filter: 'brightness(100)'}}/>
                        </div>
                         <p style={{marginBottom:'0'}}>150106, Oʼzbekiston Respublikasi, Qo'qon shahri, Zarbuloq ko'chasi, 48 - uy</p>
                  </div>
                  <div>
                        <div>
                        <img src={phone} alt="phone" style={{filter: 'brightness(100)'}}/>
                        </div>
                         <p style={{marginBottom:'0'}}>+998 (73) 543-13-54</p>
                  </div>
                  <div>
                        <div>
                        <img src={mail} alt="mail" style={{filter: 'brightness(100)'}}/>
                        </div>
                         <p style={{marginBottom:'0'}}>info@qoqon.depo2.uz</p>
                  </div>

                </div>
                <div className="subscribe-box-footer" id="newsletter-content">
                <h6 class="header-footer-boxes">  Yangiliklardan xabardor bo'lish</h6>
                <p style={{marginBottom:'0'}}>Elektron pochta manzilingizni kiritgan holda yangiliklar va maslahatlar bilan to'g'ridan to'g'ri tanishing </p>
                <form action="/subscribe" method="POST">
                  <input type="hidden" name="agree" id="agree" value="I agree"/>
                  <div className="subscibe-input-footer">
                     <img src={mail} alt="mail"/>
                     <input placeholder="Elektron pochta manzilingiz" type="email" name="email" id="useremail"/>
                     <button class="btn-sub-footer" type="submit"><img src={right} alt=""/></button>
                  </div>
                </form>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="left-bottom-foot">
                        <p style={{marginBottom:'0'}}>Sayt ma'lumotlaridan foydalanilganda manba ko'rsatilishi shart!</p>
                        <p style={{marginBottom:'0'}}>Saytdagi barcha materiallardan quyidagi litsenziya bo‘yicha foydalanish mumkin: <a style={{color: '#fff', textDecoration: 'none'}} href="https://creativecommons.org/licenses/by/4.0/" target="_blank"> Creative Commons Attribution 4.0 International.</a></p>
                    </div>
                    <img src={itlogo} alt="itlogo" width={100} style={{filter: 'brightness(0.999999)'}}/>
                </div>
              
            </div>
        </footer>
    );

};

export default Footer;