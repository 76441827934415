import React, { useState } from 'react';
import { FaChalkboardUser, FaUsersLine, FaFlaskVial, FaScrewdriverWrench, FaCompassDrafting, FaBook, FaWrench, FaMapLocationDot, FaRoute    } from 'react-icons/fa6'; // Import an icon for demonstration
import '../asstes/css/smart.css'; 

const Smart = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState('');

    const correctPassword = '1234';

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setIsAuthenticated(true);
            setError('');
        } else {
            setError('Incorrect password, please try again.');
        }
    };

    const cards = [
        { title: 'Depo navbatchisi', link: 'https://tch-qoqon.uz/d_login.php', icon: <FaChalkboardUser /> },
        { title: 'Naryadchi', link: '#', icon: <FaUsersLine /> },
        { title: 'Laboratoriya', link: 'https://tch-qoqon.uz/labq/', icon: <FaFlaskVial /> },
        { title: "Ta'mir tahlil", link: 'https://tch-qoqon.uz/remont/index.php', icon: <FaScrewdriverWrench /> },
        { title: 'Rasshifrovka', link: 'https://tch-qoqon.uz/remont/index.php', icon: <FaCompassDrafting/>},
        { title: 'E-NOTES', link: 'https://depouzbekistan.uz/dpx-lib/', icon: <FaBook />},
        { title: 'Asbob-uskuna', link: 'https://airtable.com/login?continue=%2FappnEmfvh8SEv3ZwC%2FtblhA6YeJAScgxxkN%2Fviw2oViXqsRoI1zoZ%3Fblocks%3Dhide&redirectSource=liveapp', icon: <FaWrench />},
        { title: 'E-MAP', link: '#', icon: <FaMapLocationDot />},
        { title: 'GPS tizimi', link: 'https://utysmpo.uzgps.uz/', icon: <FaRoute />},
    ];

    if (isAuthenticated) {
        return (
            <div className="smart-container">
                <div className='container'>
                <div className="card-grid">
                    {cards.map((card, index) => (
                        <a href={card.link} key={index} className="card">
                            <div className="card-icon">{card.icon}</div>
                            <h3 className="card-title">{card.title}</h3>
                        </a>
                    ))}
                </div>
                </div>
            </div>
        );
    }

    return (
        <div className="password-container">
            <h6>Ограниченный контент <br/>
            Чтобы просмотреть этот защищенный контент, введите пароль ниже:</h6>
            <form onSubmit={handleSubmit}>
                <input 
                    type="password" 
                    placeholder="Enter password" 
                    value={password} 
                    onChange={handlePasswordChange} 
                    required
                />
                <button type="submit">Войти</button>
            </form>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Smart;
