import React from 'react';
const NoPage = () => {
    return (
      <>
       <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
          <img src='https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif'  width={400}/>
          <h3 style={{fontFamily: 'Roboto', color: '#333'}}>Sahifa ishlab chiqish jarayonida</h3>
          <h2 style={{marginBottom: '50px', fontFamily: 'Roboto', color: '#333'}}>Noqulaylik uchun uzur so'raymiz</h2>
       </div>
      </>
    );
  };
  
  export default NoPage;