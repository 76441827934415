import React from 'react';
import { Link } from 'react-router-dom';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'
import row from '../asstes/images/right.svg';

const Regions = () => {
    return (
        <div className='emblem-container'>
                <div className='emblem-title'>
                  <div className='container'>
                    <h1>Hududiy tarkiblar</h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft: '0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <a href="/">Asosiy sahifa</a>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li class="active">
                                    <img src={right} alt='right'/>
                                     <a>Hududiy tarkib </a>
                                     <meta property="position" content="2"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <a href="#" onclick="window.print()" style={{display: 'none'}}>
                                <img src={print} alt=""/>
                            Chop etish
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
                    <div className='regions-container'>
                        <div className='container'>
                            <div className='position-box'>
                                <div className='position-holder'>
                                    <img src='https://png.pngtree.com/png-clipart/20230927/original/pngtree-d-illustration-of-industrial-factory-building-representing-factory-buildings-with-industrial-png-image_13148467.png' alt=''/>
                                </div>
                                <div className='about-position'>
                                    <div>
                                        <h1>Qo'qon lokomotiv deposi Andijon filiali</h1>
                                    </div>
                                    <div>
                                        <span>
                                           <strong>Yurudik manzili :</strong>  170100 Andijon viloyati, Andijon shaxar A.Fitrat ko'chasi 10 uy
                                        </span>

                                        <span>
                                            <strong>Telefon raqami: </strong>
                                        </span>
                                        <span>
                                            <strong>E-pochta manzili: </strong> info@andijon.depo2.uz
                                        </span>
                                        <span>
                                            <strong>Rahbari: </strong>
                                        </span>
                                        <span></span>
                                        <span>
                                            <Link to='https://yandex.uz/maps/10329/andijan/?ll=72.356486%2C40.768192&mode=whatshere&whatshere%5Bpoint%5D=72.356422%2C40.768148&whatshere%5Bzoom%5D=19.27&z=19.27' className='map'>Xaritada ko'rish <img src={row} alt="row"/></Link>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            {/* Marg'ilon */}
                            <div className='position-box'>
                                <div className='position-holder'>
                                    <img src='https://png.pngtree.com/png-clipart/20230927/original/pngtree-d-illustration-of-industrial-factory-building-representing-factory-buildings-with-industrial-png-image_13148467.png' alt=''/>
                                </div>
                                <div className='about-position'>
                                    <div>
                                        <h1>Marg'ilon aylanma deposi</h1>
                                    </div>
                                    <div>
                                        <span>
                                           <strong>Yurudik manzili :</strong>  
                                        </span>

                                        <span>
                                            <strong>Telefon raqami: </strong>
                                        </span>
                                        <span>
                                            <strong>E-pochta manzili: </strong> 
                                        </span>
                                        <span>
                                            <strong>Rahbari: </strong>
                                        </span>
                                        <span></span>
                                        <span>
                                            <Link to='' className='map'>Xaritada ko'rish <img src={row} alt="row"/></Link>
                                        </span>
                                    </div>
                                </div>

                            </div>
                              {/* Namangan */}
                            <div className='position-box'>
                                <div className='position-holder'>
                                    <img src='https://png.pngtree.com/png-clipart/20230927/original/pngtree-d-illustration-of-industrial-factory-building-representing-factory-buildings-with-industrial-png-image_13148467.png' alt=''/>
                                </div>
                                <div className='about-position'>
                                    <div>
                                        <h1>Namangan aylanma deposi</h1>
                                    </div>
                                    <div>
                                        <span>
                                           <strong>Yurudik manzili :</strong>  
                                        </span>

                                        <span>
                                            <strong>Telefon raqami: </strong>
                                        </span>
                                        <span>
                                            <strong>E-pochta manzili: </strong> 
                                        </span>
                                        <span>
                                            <strong>Rahbari: </strong>
                                        </span>
                                        <span></span>
                                        <span>
                                            <Link to='' className='map'>Xaritada ko'rish <img src={row} alt="row"/></Link>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
        </div>
    );
};

export default Regions;