import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs"; // Предположим, что это компонент для новостей
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Smart from "./pages/Smart";
import Emblems from "./pages/Emblems";
import About from "./pages/About";
import Admin from "./pages/Admin";
import NewsDetails from "./pages/NewsDetails";
import ErrorBoundary from "./components/ErrorBoundary";
import Regions from "./pages/Regions";
import Kasaba from "./pages/Kasaba";
import Document from "./pages/Document";
import News from "./pages/Blogs"; // Предположим, что это компонент для новостей

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Основной макет */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} /> {/* Страница новостей */}
          <Route path="contact" element={<Contact />} />
          <Route path="smart" element={<Smart />} />
          <Route path="davlat-ramzlari" element={<Emblems />} />
          <Route path="about" element={<About />} />
          <Route path="newsall" element={<News />} />
          <Route path="regions" element={<Regions />} />
          <Route path="kasaba" element={<Kasaba />} />
          <Route path="tehnicheskie-dokumenty" element={<Document />} />
          <Route
            path="news-detal/:id"
            element={
              <ErrorBoundary>
                <NewsDetails />
              </ErrorBoundary>
            }
          />
          <Route path="*" element={<NoPage />} /> {/* Обработка отсутствующих страниц */}
        </Route>

        {/* Отдельный маршрут для Административной панели */}
        <Route path="/admin/*" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
}

// Рендеринг приложения
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
