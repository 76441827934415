import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "../asstes/css/GalleryPage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
const GalleryPage = () => {
    const [photos, setPhotos] = useState([]);
    const [photoFile, setPhotoFile] = useState(null);
    const [photoTitle, setPhotoTitle] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [editingPhoto, setEditingPhoto] = useState(null); // Новое состояние для редактирования
    const [newPhotoTitle, setNewPhotoTitle] = useState(""); // Новое состояние для нового названия фотографии

    const photosPerPage = 5; // Количество фотографий на странице

    useEffect(() => {
        loadPhotos();
    }, []);

    const loadPhotos = async () => {
        try {
            const response = await axios.get("http://activ.depo2.uz/photos");
            setPhotos(response.data);
        } catch (err) {
            console.error("Ошибка при загрузке фотографий:", err);
        }
    };

    const handlePhotoUpload = async (e) => {
        e.preventDefault();

        if (!photoFile || !photoTitle) {
            alert("Заполните все поля!");
            return;
        }

        const formData = new FormData();
        formData.append("title", photoTitle);
        formData.append("photo", photoFile);

        try {
            await axios.post("http://activ.depo2.uz/photos", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setPhotoTitle("");
            setPhotoFile(null);
            loadPhotos();
        } catch (err) {
            console.error("Ошибка при загрузке фотографии:", err);
        }
    };

    const deletePhoto = async (id) => {
        try {
            await axios.delete(`http://activ.depo2.uz/photos/${id}`);
            loadPhotos();
        } catch (err) {
            console.error("Ошибка при удалении фотографии:", err);
        }
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // Рассчитываем данные для отображения на текущей странице
    const offset = currentPage * photosPerPage;
    const currentPhotos = photos.slice(offset, offset + photosPerPage);

    const handleEditClick = (photo) => {
        setEditingPhoto(photo); // Открываем форму редактирования
        setNewPhotoTitle(photo.title); // Заполняем новое название фото
    };

    const handleSaveEdit = async (e) => {
        e.preventDefault();
    
        if (!newPhotoTitle) {
            alert("Введите новое название фотографии!");
            return;
        }
    
        const formData = new FormData();
        formData.append("title", newPhotoTitle);
    
        if (photoFile) {
            formData.append("photo", photoFile);
        }
    
        try {
            const response = await axios.put(
                `http://activ.depo2.uz/photos/${editingPhoto.id}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
    
            // Обновляем фотографию в локальном состоянии
            setPhotos((prevPhotos) =>
                prevPhotos.map((photo) =>
                    photo.id === editingPhoto.id
                        ? { ...photo, title: response.data.title, file_path: response.data.file_path }
                        : photo
                )
            );
    
            // Сброс состояния редактирования
            setEditingPhoto(null);
            setNewPhotoTitle("");
            setPhotoFile(null);
        } catch (err) {
            console.error("Ошибка при редактировании фотографии:", err);
            alert("Произошла ошибка при редактировании фотографии.");
        }
    };
    
    
    return (
        <div className="content">
            <h2>Галерея</h2>
            <form onSubmit={handlePhotoUpload} className="upload-form">
                <input
                    type="text"
                    placeholder="Название фотографии"
                    value={photoTitle}
                    onChange={(e) => setPhotoTitle(e.target.value)}
                    className="form-control"
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setPhotoFile(e.target.files[0])}
                    className="form-control"
                    required
                />
                <button type="submit" className="btn-upload">Загрузить</button>
            </form>
            <table className="photo-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                        <th>Фото</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPhotos.map((photo) => (
                        <tr key={photo.id}>
                            <td style={{width: '50px', textAlign: 'center'}}>{photo.id}</td>
                            <td>{photo.title}</td>
                            <td>
                                <img
                                    src={photo.file_path}
                                    alt={photo.title}
                                    className="photo"
                                />
                            </td>
                            <td style={{width: '150px'}}>
                                <a href="#"
                                    className="btn-delete"
                                    onClick={() => deletePhoto(photo.id)}
                                >
                                    Удалить
                                </a>
                                <a href="#"
                                    className="btn-edit"
                                    onClick={() => handleEditClick(photo)}
                                >
                                    Редактировать
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingPhoto && (
                <div className="edit-form">
                    <h3>Редактирование фотографии</h3>
                    <form onSubmit={handleSaveEdit}>
                        <input
                            type="text"
                            placeholder="Новое название"
                            value={newPhotoTitle}
                            onChange={(e) => setNewPhotoTitle(e.target.value)}
                            className="input"
                            required
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setPhotoFile(e.target.files[0])}
                            className="input"
                        />
                        <button type="submit" className="btn-upload">Сохранить</button>
                        <button type="button" onClick={() => setEditingPhoto(null)} className="btn-cancel">
                            Отменить
                        </button>
                    </form>
                </div>
            )}

            <ReactPaginate
                previousLabel={"← Назад"}
                nextLabel={"Далее →"}
                breakLabel={"..."}
                pageCount={Math.ceil(photos.length / photosPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
            />
        </div>
    );
};

export default GalleryPage;
