import React, { useState } from 'react';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'
const About = () => {
    return (
        <div className='emblem-container'>
                <div className='emblem-title'>
                  <div className='container'>
                    <h1>Depo haqida</h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft:'0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <a href="/">Asosiy sahifa</a>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li class="active">
                                    <img src={right} alt='right'/>
                                     <a  href="#" style={{cursor: 'text'}}>Depo haqida </a>
                                     <meta property="position" content="2"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <a href="#" onclick="window.print()">
                                <img src={print} alt=""/>
                            Chop etish
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
        </div>
    );
};

export default About;