import React, { useEffect } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import NewsPage from "./NewsPage";
import GalleryPage from "./GalleryPage";
import "../asstes/css/AdminPage.css";
import { FaRegNewspaper } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
const AdminPage = () => {
    const location = useLocation();

    useEffect(() => {
        const jivoContainer = document.getElementById("jivo_container");
        if (jivoContainer) {
            if (location.pathname.includes("/admin")) {
                jivoContainer.style.display = "none"; // Скрыть Jivo Chat
            } else {
                jivoContainer.style.display = "block"; // Показать Jivo Chat
            }
        }
    }, [location.pathname]);

    return (
        <div className="admin-container">
            {/* Sidebar */}
            <aside className="sidebar">
                <div className="sidebar-header">Admin Panel</div>
                <nav className="sidebar-menu">
                    <ul>
                        <li>
                        <FaRegNewspaper /> <Link to="news"> Новости</Link>
                        </li>
                        <li>
                        <GrGallery /> <Link to="gallery">Галерея</Link>
                        </li>
                    </ul>
                </nav>
            </aside>

            {/* Main Content */}
            <div className="main-content">
                <Routes>
                    <Route path="news" element={<NewsPage />} />
                    <Route path="gallery" element={<GalleryPage />} />
                </Routes>
            </div>
        </div>
    );
};

export default AdminPage;
